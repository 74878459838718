<app-header></app-header>
<div class="container white-text center">
    <img src="../../assets/imgs/profile.jpg" alt="">
    <div class="mussane-header">
        <h1>Mussane Beats</h1>
    </div>
    <p>Type beats made to get you heard and make your songs stand out. Made by Andrej Oljaca.</p>
    <iframe src="https://player.beatstars.com/?storeId=113416" width="100%" height="800" style="max-width:1024px;"> -- none -- </iframe>
    <p>Andrej Oljaca is the creator of these beats and goes by the producer name Mussane Beats.</p>
</div>
<app-footer></app-footer>



