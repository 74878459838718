<footer class="page-footer">
    <div class="container">
      <div class="row">
        <div class="col l6 s12">
          <h5 class="white-text">About</h5>
          <p class="grey-text text-lighten-4">Going by the producer name Mussane Beats, this site is run by Andrej Oljaca.</p>
        </div>
        <div class="col l4 offset-l2 s12">
          <ul class="icon-flex">
            <li><a class="grey-text text-lighten-3" href="https://www.facebook.com/mussane.beats.1/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
            <li><a class="grey-text text-lighten-3" href="https://twitter.com/MussaneBeats" target="_blank"><i class="fab fa-twitter"></i></a></li>
            <li><a class="grey-text text-lighten-3" href="https://www.youtube.com/channel/UCPUbIznNTf3syIPgDc9rjHQ" target="_blank"><i class="fab fa-youtube"></i></a></li>
            <li><a class="grey-text text-lighten-3" href="https://www.instagram.com/mussane.beats/" target="_blank"><i class="fab fa-instagram"></i></a></li>
            <li><a class="grey-text text-lighten-3" href="#" routerLink="/contact"><i class="fas fa-envelope-square" target="_blank"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="container">
      © 2021 Copyright Andrej Oljaca
      </div>
    </div>
</footer>
