<app-header></app-header>
<div class="container contact-form">
    <div class="row">
        <div class="col m10 offset-m1 s12">
            <h2 class="center-align">Contact Form</h2>
            <div class="row">
                <form id="contact-form" class="col s12" (submit)="sendEmail($event)">
                    <div class="row">
                        <div class="input-field col m6 s12">
                            <input type="hidden" name="contact_number">
                            <input id="first_name" type="text" name="first_name" class="validate" required>
                            <label for="first_name" id="first_name_label">First Name</label>
                        </div>
                        <div class="input-field col m6 s12">
                            <input id="last_name" type="text" class="validate" name="last_name">
                            <label for="last_name" id="last_name_label">Last Name</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col m6 s12">
                            <i class="mdi-content-mail prefix"></i>
                            <input id="email" type="email" name="email" class="validate" required>
                            <label for="email" id="email_label">Email</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                          <textarea id="message" class="materialize-textarea" name="message" required></textarea>
                          <label for="message" id="message_label">Message</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col m12">
                         <p class="right-align"><button #inputElement class="btn-submit btn-large waves-effect waves-light" type="submit" name="action">Send Message</button></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
