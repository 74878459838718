import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicVsPremiumComponent } from './basic-vs-premium/basic-vs-premium.component';
import { ContactComponent } from './contact/contact.component';
import { MainPageComponent } from './main-page/main-page.component';

const routes: Routes = [
  {path: '', redirectTo: '/beats', pathMatch: 'full'},
  {path: 'beats', component: MainPageComponent},
  {path: 'basic-vs-premium', component: BasicVsPremiumComponent},
  {path: 'contact', component: ContactComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
