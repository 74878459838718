import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-vs-premium',
  templateUrl: './basic-vs-premium.component.html',
  styleUrls: ['./basic-vs-premium.component.scss']
})
export class BasicVsPremiumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
