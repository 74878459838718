<nav>
    <div class="nav-wrapper">
        <a href="#!" class="brand-logo center">Mussane Beats</a>
        <img src="../../assets/imgs/Real Logo Copy.jpg" class="brand-logo left img" alt="">
        <a href="#" data-target="mobile-demo" class="sidenav-trigger right" (click)="click()"><i class="fas fa-bars"></i></a>
        <ul class="right hide-on-med-and-down">
            <li><a href="#" routerLink="/" (click)="click()">Beats</a></li>
            <li><a href="#" routerLink="/basic-vs-premium" (click)="click()">Basic vs Premium</a></li>
            <li><a href="#" routerLink="/contact" (click)="click()">Contact</a></li>
        </ul>
    </div>
</nav>

<ul class="sidenav" id="mobile-demo">
    <li><a href="#" routerLink="/" (click)="click()">Beats</a></li>
    <li><a href="#" routerLink="/basic-vs-premium" (click)="click()">Basic vs Premium</a></li>
    <li><a href="#" routerLink="/contact" (click)="click()">Contact</a></li>
</ul>

