import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        var $nav = $("nav");
        var $img = $(".img")
        $img.css("height", $nav.height()+"px");
      }

      if (event instanceof NavigationEnd) {
        var $nav = $("nav");
        var $img = $(".img")
        $img.css("height", $nav.height()+"px");
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }
  });
   }

  ngOnInit(): void {
  }

  click() {
    // @ts-ignore
    $('.sidenav').sidenav();
    var $nav = $("nav");
    var $img = $(".img")
    $img.css("height", $nav.height()+"px");
  }

}
