import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('inputElement') inputElement: any; 
  constructor() { }
  ngOnInit(): void {
  }
  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_60j5gag', 'template_0e2tsdc', e.target as HTMLFormElement, 'user_JLX3kYqgK9eyb1mOjksOx')
      .then((result: EmailJSResponseStatus) => {
        setTimeout(()  => {
          this.inputElement.nativeElement.innerHTML = 'Message Sent'
        }, 700)
        setTimeout(() => {
           this.inputElement.nativeElement.innerHTML = 'Send Message'
        }, 7000)
      }, (error) => {
        setTimeout(()  => {
          this.inputElement.nativeElement.innerHTML = 'Error'
        }, 700)
        setTimeout(() => {
           this.inputElement.nativeElement.innerHTML = 'Send Message'
        }, 7000)
      });
  }
}
